export const semanticTokens = {
  colors: {
    text: {
      default: "yottaBlackNeutral",
      _dark: "#E6EDF3",
    },
    placeholder: {
      default: "yottaBlack",
      _dark: "tintedWhite",
    },
    "subtle-text": {
      default: "gray.400",
      _dark: "gray.400",
    },
    bg: {
      default: "#FFFFFF",
      _dark: "#1E1E1E",
    },
    bgAlpha: {
      default: "#FFFFFFCC",
      _dark: "#1E1E1ECC",
    },
    "subtle-bg": {
      default: "#F3F4EF",
      _dark: "#282828",
    },
    "bento-box": {
      default: "#F3F4EF",
      _dark: "#1C1C1C",
    },
    siteFormInput: {
      default: "#B9DC65",
      _dark: "#A2CC39",
    },
    siteFormButton: {
      default: "#B9DC65",
      _dark: "#A2CC39",
    },
    inputBgColor: {
      default: "#F3F4EF",
      _dark: "#2E2E2E",
    },
    labelBg: {
      default: "#F3F4EF",
      _dark: "#51524A",
    },
    buttonUploader: {
      default: "yottaGrayFont",
      _dark: "yottaGreyDisabled",
    },
    charts: {
      title: {
        default: "yottaBlack",
        _dark: "tintedWhite",
      },
      subTitle: {
        default: "yottaGrayFont",
        _dark: "yottaGrayFont",
      },
      refresh: {
        default: "#A6A6A6",
        _dark: "#A6A6A6",
      },
    },
    visualization: {
      bg: {
        default: "yottaGrayFont",
        _dark: "yottaBlack",
      },
      legendBg: {
        default: "yottaGrayNeutral",
        _dark: "yottaGreyNatural",
      },
      legendText: {
        default: "tintedWhite",
        _dark: "tintedWhite",
      },
      tooltipBg: {
        default: "#1E1E1ECC", // yottaBg with 80% opacity
        _dark: "#1E1E1ECC", // yottaBg with 80% opacity
      },
      tooltipText: {
        default: "tintedWhite",
        _dark: "tintedWhite",
      },
      heatmapLegendText: {
        default: "yottaBg",
        _dark: "tintedWhite",
      },
    },
    dashboard: {
      edit: {
        default: "yottaBlack",
        _dark: "tintedWhite",
      },
      text: {
        default: "yottaBlack",
        _dark: "yottaGrayFont",
      },
      values: {
        default: "tintedBlack",
        _dark: "tintedWhite",
      },
      bg: {
        default: "#EEE",
        _dark: "yottaBlack",
      },
    },
    weather: {
      text: {
        default: "yottaBlack",
        _dark: "tintedWhite",
      },
      unit: {
        default: "yottaGrayFont",
        _dark: "yottaGrayFont",
      },
      icon: {
        default: "yottaBlack",
        _dark: "tintedWhite",
      },
    },
    site: {
      name: {
        default: "yottaBlack",
        _dark: "tintedWhite",
      },
      address: {
        default: "yottaBlack",
        _dark: "tintedWhite",
      },
    },
    statCard: {
      backgroundColor: {
        default: "#F3F4EF",
        _dark: "yottaBlack",
      },
      title: {
        default: "yottaBlackNeutral",
        _dark: "tintedWhite",
      },
      units: {
        default: "yottaGreyDisabled",
        _dark: "yottaGreyDisabled",
      },
      amount: {
        default: "yottaBlackNeutral",
        _dark: "tintedWhite",
      },
    },
    loginModal: {
      default: "gray.50",
      _dark: "yottaBlack",
    },
    switcher: {
      bg: {
        default: "tintedWhite",
        _dark: "yottaBlack",
      },
      borderColor: {
        default: "yottaGrayFont",
        _dark: "yottaGrey",
      },
      iconPlaceholderColor: {
        default: "yottaGrayFont",
        _dark: "yottaGrayFont",
      },
      iconBg: {
        default: "yottaGreen",
        _dark: "yottaGreen",
      },
      iconColor: {
        default: "tintedWhite",
        _dark: "tintedWhite",
      },
    },
    devicesList: {
      default: "tintedWhite",
      _dark: "yottaBlackNeutral",
    },
    sidebarLogo: {
      default: "yottaBg",
      _dark: "tintedWhite",
    },
  },
};
